module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo-shopify/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"circuitbreakersuperstore","accessToken":"ada78223c96c03d37eb5e20819d10b07","apiVersion":"2024-01"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"."},"trailingSlashes":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
